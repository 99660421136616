<template>
  <div class="bg-light" v-if="loaded">
    <div class="mb-4" v-if="!content">
      <button
        @click="setLang(lang, true)"
        class="btn btn-xs"
        v-for="(lang, i) in langs"
        :class="{ donate: lang === language }"
        :key="i"
      >
        {{ lang }}
      </button>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 d-flex justify-content-between">
        <div>
          <h5>{{ $t("contactView.createTitle") }}</h5>
          <p class="text-muted">{{ $t("contactView.createDescription") }}</p>
        </div>
        <div>
          <button
            class="btn btn-pill text-white btn-danger mr-2"
            v-if="content"
            @click="deleteModal = true"
          >
            {{ $t("remove") }}
          </button>
          <button @click="publish()" class="btn btn-pill text-white subscribe">
            {{ $t("publish") }}
          </button>
        </div>
      </div>
      <div class="col-12 col-md-6 card mt-3">
        <div class="mb-3">
          <strong>
            {{ $t("contactView.languageView") }}:
            <span class="text-primary">{{ language }}</span>
          </strong>
        </div>
        <div class="form-group">
          <label for="title">{{ $t("contactView.title") }}</label>
          <input type="text" class="form-control" v-model="title" />
        </div>
        <div class="form-group">
          <label for="description">{{ $t("contactView.description") }}</label>
          <textarea
            type="text"
            class="form-control"
            rows="4"
            v-model="description"
          ></textarea>
        </div>
        <div class="form-group">
          <label>{{ $t("contactView.button") }}</label>
          <input type="text" class="form-control" v-model="buttonText" />
        </div>
        <no-found no-button v-if="forms.length === 0"></no-found>
        <div class="mt-4 row" v-for="(form, i) in forms" :key="i">
          <div class="col-12 col-lg-6">
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="form.label"
            />
          </div>
          <div class="col-8 mt-2 mt-lg-0 col-lg-4">
            <select
              class="form-control"
              v-model="form.type"
              @change="changeLabel(form)"
            >
              <option value="text">Text</option>
              <option value="select">Select</option>
              <option value="textarea">Textarea</option>
              <option value="checkbox">Checkbox</option>
              <option value="radio">Radio</option>
              <option value="date">Date</option>
              <option value="time">Time</option>
              <option value="email">Email</option>
              <option value="number">Number</option>
              <option value="url">URL</option>
              <option value="tel">Tel</option>
            </select>
          </div>
          <div class="col-4 mt-2 mt-lg-0 col-lg-2">
            <button
              class="btn bg-light w-100 d-block"
              @click="editModal = { status: true, form: { ...form }, index: i }"
            >
              <i class="icon-settings"></i>
            </button>
          </div>
        </div>
        <div class="mt-4">
          <button
            class="btn btn-pill w-100 bg-info text-white"
            @click="newForm()"
          >
            <i class="icon-plus"></i> {{ $t("add") }}
          </button>
        </div>
        <div class="mt-4">
          <p class="alert alert-warning">
            {{ $t("contactView.warnForPublish") }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 card mt-3">
        <h5>{{ title }}</h5>
        <p class="text-muted mb-3" v-if="description">
          <small>{{ description }}</small>
        </p>
        <no-found no-button v-if="forms.length === 0"></no-found>
        <div v-for="(form, i) in forms" :key="i">
          <div class="form-group">
            <label :for="form.label"
              >{{ form.label }}
              <span v-if="form.isRequired" class="text-danger">*</span>
            </label>
            <input
              v-if="
                [
                  'text',
                  'email',
                  'number',
                  'url',
                  'tel',
                  'date',
                  'time',
                ].includes(form.type)
              "
              :type="form.type"
              :id="form.label"
              :placeholder="form.placeholder"
              class="form-control"
            />
            <textarea
              v-if="form.type === 'textarea'"
              :id="form.label"
              :rows="form.rows"
              :placeholder="form.placeholder"
              class="form-control"
            ></textarea>
            <div v-if="form.type === 'checkbox'" class="mt-2">
              <div v-for="(option, i) in form.options" :key="i">
                <input
                  type="checkbox"
                  :id="option.value"
                  :value="option.value"
                />
                <label class="ml-2" :for="option.value">{{
                  option.value
                }}</label>
              </div>
            </div>
            <div v-if="form.type === 'radio'" class="mt-2">
              <div v-for="(option, i) in form.options" :key="i" class="d-flex">
                <input
                  style="width: 20px; height: 20px"
                  type="radio"
                  :name="form.label"
                  :id="option.value"
                  :value="option.value"
                />
                <label class="ml-2" :for="option.value">{{
                  option.value
                }}</label>
              </div>
            </div>
            <select
              v-if="form.type === 'select'"
              :id="form.label"
              :placeholder="form.placeholder"
              class="form-control"
            >
              <option
                v-for="option in form.options"
                :key="option"
                :value="option.value"
              >
                {{ option.value }}
              </option>
            </select>
            <div v-if="form.hint" class="text-muted">
              <small> {{ form.hint }}</small>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <button
            class="btn btn-pill text-white"
            :style="{ backgroundColor: getSettings.primaryColor }"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
    <modal v-model="editModal.status" :title="$t('edit')" @ok="saveEdit()">
      <div class="d-flex justify-content-end mb-3">
        <button class="btn btn-pill bg-danger text-white" @click="deleteForm()">
          <i class="icon-trash"></i> {{ $t("remove") }}
        </button>
      </div>
      <div class="form-group">
        <label for="label">{{ $t("label") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="editModal.form.label"
        />
      </div>
      <div class="form-group">
        <label for="placeholder">{{ $t("placeholder") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="editModal.form.placeholder"
        />
        <small class="text-muted">{{ $t("placeholderDescription") }}</small>
      </div>
      <div class="form-group">
        <label for="type">{{ $t("type") }}</label>
        <select class="form-control" v-model="editModal.form.type">
          <option value="text">Text</option>
          <option value="select">Select</option>
          <option value="textarea">Textarea</option>
          <option value="checkbox">Checkbox</option>
          <option value="radio">Radio</option>
          <option value="date">Date</option>
          <option value="time">Time</option>
          <option value="email">Email</option>
          <option value="number">Number</option>
          <option value="url">URL</option>
          <option value="tel">Tel</option>
        </select>
      </div>
      <div class="form-group">
        <label for="hint">{{ $t("hint") }}</label>
        <input type="text" class="form-control" v-model="editModal.form.hint" />
        <small class="text-muted">{{ $t("hintDescription") }}</small>
      </div>
      <div class="form-group">
        <input
          type="checkbox"
          id="isRequired"
          v-model="editModal.form.isRequired"
        />
        <label class="ml-2" for="isRequired">{{ $t("isRequired") }}</label>
      </div>
      <div class="form-group" v-if="editModal.form.type === 'textarea'">
        <label for="rows">{{ $t("rows") }}</label>
        <input
          type="number"
          class="form-control"
          v-model="editModal.form.rows"
        />
      </div>
      <div
        class="form-group"
        v-if="['select', 'radio', 'checkbox'].includes(editModal.form.type)"
      >
        <label for="options">{{ $t("options") }}</label>
        <div v-for="(option, i) in editModal.form.options" :key="i">
          <input type="text" class="form-control mt-2" v-model="option.value" />
        </div>
        <div>
          <button
            class="btn btn-pill bg-light btn-xs mt-3"
            @click="addOption()"
          >
            <i class="icon-plus"></i> {{ $t("addOption") }}
          </button>
        </div>
      </div>
    </modal>
    <modal
      sm
      v-model="deleteModal"
      :okText="$t('remove')"
      :title="$t('remove')"
      @ok="deleteContent()"
    >
      <p>{{ $t("contactView.deleteModalText") }}</p>
    </modal>
  </div>
</template>

<script>
import NoFound from "../../../Shared/NoFound.vue";
import Modal from "../../../Shared/Modal/index.vue";
import ActionMixin from "../action.mixin.js";
export default {
  mixins: [ActionMixin],
  components: { NoFound, Modal },
  data() {
    return {
      loaded: false,
      deleteModal: false,
      langs: ["TR", "EN", "DE", "KG", "KZ"],
      language: "TR",
      title: this.$t("contactView.formTitleExample"),
      description: this.$t("contactView.formDescriptionExample"),
      buttonText: this.$t("submit"),
      editModal: {
        status: false,
        index: -1,
        form: {},
      },
      forms: [
        {
          label: this.$t("contactView.formExample1"),
          placeholder: this.$t("contactView.formExample1Placeholder"),
          type: "text",
          isRequired: true,
          rows: 3,
          options: [],
          hint: "",
        },
        {
          label: this.$t("contactView.formExample2"),
          placeholder: this.$t("contactView.formExample2Placeholder"),
          type: "text",
          isRequired: true,
          rows: 3,
          options: [],
          hint: "",
        },
        {
          label: this.$t("contactView.formExample3"),
          placeholder: this.$t("contactView.formExample3Placeholder"),
          type: "email",
          isRequired: true,
          rows: 3,
          options: [],
          hint: "",
        },
        {
          label: this.$t("contactView.formExample4"),
          placeholder: this.$t("contactView.formExample4Placeholder"),
          type: "textarea",
          isRequired: true,
          rows: 3,
          options: [],
          hint: this.$t("contactView.formExample4Hint"),
        },
      ],
      forceProps: {
        title: "",
        description: "",
        degrees: "",
        overview: "",
        type: 11,
      },
    };
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setLang(lang) {
      this.language = lang;
    },
    changeLabel(form) {
      console.log(form.type);

      if (["select", "radio", "checkbox"].includes(form.type)) {
        this.editModal = {
          status: true,
          form,
        };
      }
    },
    newForm() {
      this.forms.push({
        label: "",
        placeholder: "",
        type: "text",
        options: [],
        hint: "",
      });
    },
    addOption() {
      this.editModal.form.options.push({
        value: "",
      });
    },
    saveEdit() {
      this.forms[this.editModal.index] = this.editModal.form;
      this.editModal.status = false;
    },
    deleteForm() {
      this.forms.splice(this.editModal.index, 1);
      this.editModal.status = false;
    },
    publish() {
      const form = {
        title: this.title,
        description: this.description,
        degrees: this.buttonText,
        overview: JSON.stringify(this.forms),
        type: 11,
        language: this.language,
      };
      if (this.content?.id) {
        this.contentPut(this.content.id, form);
      } else {
        this.contentCreate(form, this.pushContent);
      }
    },
    pushContent() {
      this.$router.push({
        name: "MyAcademyContentContactView",
      });
    },
    setContent() {
      console.log(this.content);
      if (!this.content) {
        this.loaded = true;
        return;
      }
      this.title = this.content.title;
      this.description = this.content.description;
      this.buttonText = this.content.degrees;
      this.forms = JSON.parse(this.content.overview);
      this.language = this.content.language;
      this.loaded = true;
    },
    deleteContent() {
      this.contentDelete(this.content.id, this.pushContent);
    },
  },
  mounted() {
    this.setContent();
  },
};
</script>

<style></style>
